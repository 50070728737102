import { useState, useEffect, useCallback } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState(false);
  const [tokenExpiration, setTokenExpiration] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [profilePic, setProfilePic] = useState('');

  const login = useCallback((profilePic,uid, token, expirationDate) => {
    setToken(token);
    setUserId(uid);
    setIsLoggedIn(true);
    setProfilePic(profilePic);
    const tokenExpirationDate = expirationDate ||  new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpiration(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        isLoggedIn: true,
        profilePic: profilePic,
        userId: uid,
        token: token,
        expiration : tokenExpirationDate.toISOString()
      })
    )
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setToken(null);
    setUserId(false);
    setTokenExpiration(null);
    setProfilePic('');
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (token && tokenExpiration){
      const remainingTime = new Date(tokenExpiration).getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else{
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpiration]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if(storedData && storedData.token && new Date(storedData.expiration) > new Date() ){
      login(storedData.profilePic, storedData.userId, storedData.token, new Date(storedData.expiration));
    }
  }, [login]);

  return { isLoggedIn, profilePic,  token, login, logout, userId };

};