import React from 'react'

let Shoporder = [
    {
        logo:"./img/buildingowner/logo1.png",
        name:"Starbucks ",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo2.png",
        name:"Yayoi Restaurant",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo3.png",
        name:"Santa Fe' Steak",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo4.png",
        name:"Greyhound Cafe",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo5.png",
        name:"Domino's Pizza",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo6.png",
        name:"sizzler",
        totalOrder:"Today 10 Delivered orders."
    }

]

const ShopList = () => {
  return (
    <>

    <h3 className='sh-list'>Shop List :</h3>

        {
            Shoporder.map((shop)=>{
                console.log(shop);
                const{logo,name,totalOrder}=shop
                return(
                    <div className='shop-relative'>
                        <div className='shop-sell-pro'>
                            <div>
                                <img src={logo} alt='shop'/>
                            </div>
                            <div className='classic-shop'>
                                <h3>{name}</h3>
                                <p>{totalOrder}</p>
                            </div>
                        </div>
                        <img className='circle-icon' src='./img/circle-right.png' alt='arrow icon'/>
                    </div>
                )

            })
        }

    </>
  )
}

export default ShopList