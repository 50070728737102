import React from 'react'

const Choice = ({hotel}) => {
  return (
    <li className='flex-trip-hotel'>
        <img src={hotel.picture} alt="test hotel"/>
        <p className='hotel-name'>{hotel.hotelName}</p>
    </li>
  )
}

export default Choice