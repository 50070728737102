import React from 'react';
import BoBanner2 from '../components/BoBanner2';
import ShoperDetail from '../components/ShoperDetail'

const BuildingOwnerPage2 = () => {
  return (
    <>
        <div className='bo-wrapper-2'>
            <BoBanner2/>
            <ShoperDetail/>
        </div>
    
    </>
  )
}

export default BuildingOwnerPage2
