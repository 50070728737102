import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Header = ({image1,alt,alt2,image2,location}) => {
  const auth = useContext(AuthContext);
  return (
    <header className='header'>
        <p className='flex-header'>
            <Link to={'/'}>
            <img style={{width:"3rem",}} src={image1} alt ={alt}/>
            </Link>
            <span className='header-name'>{location}</span>
        </p>
        <Link to={`/profile/${auth.userId}`}>
        <img className='profileimg' style={{width:"5rem"}} src={image2} alt={alt2}/>
        </Link>
    </header>
  )
}

export default Header