import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Status = (props) => {
  // console.log('status');
  //   console.log(props);
    const navigate = useNavigate();
    const [generateToken, setGenerateToken] = useState(null);
    const [bridgeUid, setBridgeUid] = useState(null);
    const [placeItemCode, setPlaceItemCode] = useState(null);
    const [pickupCode, setPickupCode] = useState(null);
    const [startPoint, setStartPoint] = useState(null);
    const [deliveryState, setDeliveryState] = useState([]);
    const [cancelState, setCancelState] = useState(false);
    const [cancelClicked, setCancelClicked] = useState(false);
  
    useEffect(() => {
      if (props) {
        const { token, bridgeId, fromLocation, toLocation, taskId } = props;
        //  console.log('Token:', token);
        //  console.log('BridgeId:', bridgeId);
        setGenerateToken(token);
        setBridgeUid(bridgeId);
        // Now you can use token and otherData as needed
        // Fourth API call with the generated token and other information
          const fetchDeliveryStateRecords = async () => {
            try {
          const thirdApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization : 'Bearer ' + props.token
            },
            body: JSON.stringify({
              "bridge_link_uids" : [props.bridgeId]
          })
            // Other data you need to pass
          });
          
          const thirdApiResult = await thirdApiResponse.json();
          // console.log(thirdApiResult);
          if(thirdApiResult.data[0]){
          const placeItemCode = thirdApiResult.data[0].dispatch_info.logical_code;
          const pickupCode = thirdApiResult.data[0].dispatch_info.pickup_code;
          const startPoint = thirdApiResult.data[0].tasks[0].start_poi;
          const deliveryStatus = thirdApiResult.data[0].tasks[0].delivery_state_records;
          // console.log('status');
          // console.log(thirdApiResult.data[0]);
          // console.log(startPoint);
          setPlaceItemCode(placeItemCode);
          setPickupCode(pickupCode);
          setStartPoint(startPoint);
          if (deliveryStatus && deliveryStatus.length > 0) {
            setDeliveryState(deliveryStatus[deliveryStatus.length - 1]);
          }
          // setDeliveryState(deliveryStatus);
          }
        }
        catch (error) {
          console.error('Error:', error);
        }
      }
      fetchDeliveryStateRecords();
        // Make sure to handle cases where token or otherData might be undefined
      }
    }, [props,deliveryState, placeItemCode,pickupCode, startPoint, generateToken, bridgeUid]);
    // [props,deliveryState, placeItemCode,pickupCode, startPoint, generateToken, bridgeUid]

    useEffect(() => {
        if(cancelClicked){
          const handleCancel = async () => {
            // console.log('props.taskId');
            //  console.log(props.taskId);
            // console.log(deliveryState);
            try {
              if(props.token){
          // Third API call with the generated token and other information
          // Query bridge tasks API
          const summonTaskResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization : 'Bearer ' + props.token
            },
            body: JSON.stringify({
              "bridge_link_uids" : [props.taskId]
            })
            
          });
          
          const summonTaskApiResult = await summonTaskResponse.json();
          const taskId = summonTaskApiResult.data[0].tasks[0].task_id;

          // console.log('summonTaskApiResult');
          // console.log(taskId);
               // Second API call with the generated token and other information
              const cancelApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task/action?site_uid=1230', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization : 'Bearer ' + props.token
                },
                body: JSON.stringify({
                  "action" : 'cancel',
                  "task_id" : taskId,
                  "reason" : "cancel by user"
                })
               
              });
              
              const cancelApiResult = await cancelApiResponse.json();
              setCancelState(true);
              // console.log(cancelApiResult);
              // console.log(cancelState);
              // Delayed navigation after receiving the API result
              setTimeout(() => {
                navigate('/delivery');
              }, 3000); // Delay of 3 seconds
              // navigate('/delivery');
            }
            } catch (error) {
              console.error('Error:', error);
            }
            }
            handleCancel();
        }
    }, [cancelClicked, navigate]);

      const handleCancelClick = () => {
        setCancelClicked(true);
      };

  return (
    <section className='status-sec'>
    <div className='status-div'>
        <p className='live-status'><b>Status : </b> 
         {!cancelState && deliveryState.to === 'created' &&  <span> Delivery Initiated </span> }
         {!cancelState && deliveryState.to === 'collected' &&  <span> Item collected, Arriving Soon! </span> }
         {!cancelState && deliveryState.to === 'shipping' &&  <span> On my way to deliver your item! </span> }
         {!cancelState && deliveryState.to === 'arrived' &&  <span> I have arrived, Please collect the item </span> }
         {!cancelState && deliveryState.to === 'success' &&  <span> Delivery Successfull </span> }
         {cancelState && <span> Cancelled Successfully </span> }
        </p>
        <div className='grid-small-place'>
            <div className='fs-wrap'>
                <img src='/img/Ybot-fast-small.png' alt='ybot'></img>
            </div>
            <div className='wrap-placetext'>
                <p>YBot Delivery</p>
                <div className='place-text'>
                    <p>{props.fromLocation}</p>
                    <img id="arrow-rght" src='/img/icon.png' alt='arrow icon'></img>
                    <p>{props.toLocation}</p>
                </div>
            </div>
        </div>  
          {/* 3*/}
          <div className='psw-grid'>
            <div className='psw-wrap bg-col-1'>
                <p className='psw-head'>Collected Item <br></br>Password :</p>
                <p className='psw'>{placeItemCode ? placeItemCode : ''}</p>
            </div>
            <div className='psw-wrap bg-col-2'>
                <p className='psw-head'>Pick-Up Item <br></br>Password :</p>
                <p className='psw'>{pickupCode ? pickupCode : ''}</p>
            </div>
          </div>
          {/* 4 */}
          <div className='cancel-btn'>
            { deliveryState.to !== 'success' ? 
                <button className='text-align' onClick={handleCancelClick}>Cancel</button>
             : ''}
          </div> 
            {/* <MainBtn/>
   
            <YbotMain/>
            */}

          

    </div>

</section>
  )
}

export default Status