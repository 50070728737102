import React from 'react';
import { IoIosArrowDropright } from "react-icons/io";

const OrderRoom = ({orderDetail}) => {
    console.log(orderDetail);
    let{roomNumber,date,time,price,status}=orderDetail
  return (
    <>
        <div className='order-romm-flex'>
            <div className='inner-room-flex'>
                <div>
                    <img className="loc-icon" src='./img/iocation.png' alt='location icon'/>
                </div>
                <div className='revise-div'>
                    <p className='r-text-room'>{roomNumber}</p>
                    <p className='r-text-date'><span>{date}</span>  <span>{time}</span></p>
                </div>
            </div>
            <div className='status-div'>
                <p className='r-text-price'>{price}</p>
               
                   
                         <p className='r-text-status'>{status}</p>
              
               
            </div>
            <div>
                <a href='#' className='right-arr'><IoIosArrowDropright /></a>
            </div>
        </div>
    </>
  )
}

export default OrderRoom