import React, {useContext} from 'react';
import UserBanner  from '../components/UserBanner';
import ProfileEditbtn from '../components/ProfileEditbtn';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Cancel = () => {
    const auth = useContext(AuthContext);
  return (
    <>
     <UserBanner/>
     <div className='grid-profile-edit'>
        <Link to={`/profile/${auth.userId}`}>
                <ProfileEditbtn active='notactive'>
                Profile Edit
                </ProfileEditbtn>
            </Link>
            <Link to={'/orderHistory'}>
                <ProfileEditbtn active='active'>
                Order History
                </ProfileEditbtn>
            </Link>
        </div>
        <div className='grid-completed'>
        <Link to={'/orderHistory'} >
            Completed
            </Link>
            <Link to={'/cancel'} id='failed' >
           Cancelled /Failed
           </Link>
        </div>

        <section>
        <div className='grid-orderby'>
            <div className=''>
                <img src="./img/order-icon.png" alt="pic"/>
            </div>
            <div>
                <p className='date-time'></p>
                <p className='orderby-hotelname'><img src="./img/loc-hotel.png" alt='location icon'/> <span>Home made by A</span></p>
                <p className='orderby-hotelname'><img src="./img/loc-room.png" alt='location icon'/> <span>Room 102</span></p>
            </div>
            <div>
                <p className='price'>฿100</p>
            </div>
        </div>
        <div className='grid-oc'>
            <p className='oc-text-red'>Order Canceled By Customer</p>
            <a className='oc-btn'>Reorder</a>
        </div>
        <div className='height'></div>
        {/* <div className='grid-oc'>
            <p className='oc-text-red'>Order Canceled By Customer</p>
            <a className='oc-btn'>Reorder</a>
        </div>
        <div className='grid-oc'>
            <p className='oc-text-red'>Order Canceled By Customer</p>
            <a className='oc-btn'>Reorder</a>
        </div> */}
        </section>
      

    </>
  )
}

export default Cancel