import React, {useContext} from 'react';
import UserBanner from '../components/UserBanner';
import  UserForm from '../components/UserForm';
import ProfileEditbtn from '../components/ProfileEditbtn'
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const UserProfileEdit = () => {
  const auth = useContext(AuthContext);
  return (
    <div>
        <UserBanner/>
        <div className='grid-profile-edit'>
          <Link to={`/profile/${auth.userId}`}>
            <ProfileEditbtn active='active'>
              Profile Edit
            </ProfileEditbtn>
            </Link>
            <Link to={'/orderHistory'}>
            <ProfileEditbtn active='notactive'>
              Order History
            </ProfileEditbtn>
            </Link>
        </div>
        <UserForm/>
        
    </div>
  )
}

export default UserProfileEdit;