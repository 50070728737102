import React from 'react'
import  RoomItem from './RoomItem'
const orderItem =[
    {
       photoname:"./img/item1.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"2",
        alt:"food"
    },
    {
       photoname:"./img/item2.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"3",
        alt:"food"
    },
    {
       photoname:"./img/item3.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"1",
        alt:"food"
    }
]

export default function RoomData(){
    return(
        <div>
            {
                orderItem.map((ce) => (
                    <RoomItem value={ce}/>
                ))
            }
        </div>
    )
}



