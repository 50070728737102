import React, { useState, useEffect, useContext } from 'react';
import Order from '../components/Order';
import  Choice from '../components/Choice'
import Header from '../components/Header';
import  SliderComponent from '../components/HomeSlider';
import Button from '../components/Button';
import { useAuth } from "../hooks/auth-hook";
import { AuthContext } from "../context/AuthContext";

const hotelName =[
  {
    id: 1,
    picture:"/img/hotel1.png",
    hotelName:"Cookes to order"
  },
  {
    id: 2,
    picture:"/img/hotel2.png",
    hotelName:"Coffee & Tea"
  },
  {
    id: 3,
    picture:"/img/hotel3.png",
    hotelName:"Fast Food"
  },
  {
    id: 4,
    picture:"/img/hotel4.png",
    hotelName:"Fried Rice"
  },
  {
    id: 5,
    picture:"/img/hotel3.png",
    hotelName:"Cookes to order"
  },
  {
    id: 6,
    picture:"/img/hotel1.png",
    hotelName:"Cookes to order"
  }
]


const Menu = () => {
  const auth = useContext(AuthContext);
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(null);
  const { profilePic } = useAuth();
 console.log(auth);
  {/* FETCH API STARTS */}
  useEffect(() => {
    const fetchPlaces = async () => {
        try{
            const responseData = await fetch('https://ybot-backend.salvator.app/api/get_merchant_shops', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              }
            });
            if (!responseData.ok) {
             throw new Error('Failed to fetch shops');
            }
            const response = await responseData.json();
            console.log(response);
            setShops(response.merchantShop);
        } catch (err) {}
    };
    fetchPlaces();
}, []);
 {/* FETCH API ENDS */}

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://ybot-backend.salvator.app/api/get_merchant_shops');
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch shops');
  //       }
  //       const data = await response.json();
  //       const merchantData = data.merchantShop;
  //       console.log(merchantData);
  //       setShops(merchantData);
  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // if (error) {
  //   return <p>Error: {error}</p>;
  // }

  return (

   <div className='menu-all'>
    <Header image1 ="img/iocation.png" alt="image" location="YBot Delivery" image2={profilePic} alt2="profile pic"/>
    < SliderComponent  />
    <div className='grid-home-btn'>
        <Button btnText="Delivery" url="/img/buy-svg.svg" page="/" />
      <Button btnText="Order Food" url="/img/buy-svg.svg" page="/buy" />
    </div>
    
    {/* < Choice /> */}
    <h4 className='cuisin'>Brows Cuisines</h4>
    {/* <ul className='main-hotel-name'>
    {
      hotelName.map((hotel) => (<Choice hotel={hotel} key={hotel.id}/>))
    }
    </ul> */}
   
   {/* DATA FROM API STARTS */}
          {shops.length > 0 && shops.map(shop => (
        <Order key={shop._id} hotel={shop.name} data={shop.description} image={shop.imageUrl} menus={shop.menus} id={shop._id} />
        ))}
  {/* DATA FROM API ENDS */}

    {/* <Order hotel="Home made by A" data="Get what you desire, delivered by a robot." img1="/img/item1.png" img2="/img/item2.png" img3="/img/item3.png"/>
   <Order hotel ="MiaNonna" data="Get what you desire, delivered by a robot." img1="/img/item4.png" img2="/img/item5.png" img3="/img/item6.png"/>
   <Order hotel="Food Catering" data="Get what you desire, delivered by a robot." img1="/img/item5.png" img2="/img/item2.png" img3="/img/item4.png"/> */}
   </div>
  )
}

export default Menu