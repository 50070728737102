import React from 'react';
import Banner from '../components/Banner';
import  Room from '../components/Room';
import RoomData from '../components/RoomData';
import RoomNumber from '../components/RoomNumber';
import PrepareDoneButton from '../components/prepareDoneButton';

const MerchantPrepareOrder = () => {
  return (
    <div className='merchant'>
        <Banner/>
        <RoomNumber/>
        <RoomData/>
        <PrepareDoneButton/>
    </div>
  )
}

export default MerchantPrepareOrder