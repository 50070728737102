import React from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from "../hooks/auth-hook";

const UserBanner = () => {
  const { profilePic } = useAuth();
  return (
    <div className='user-banner'>
      <Link to={'/'}>
      <img src="/img/arrow_back.png" alt='back arrow' className='back-arrow'></img>
      </Link>
      <img src='/img/bg-item.png' alt='banner-pic'/>
         {/* <div className='overlay'></div> */}
         {/* <img src='/img/profile.jpg' alt='profile' className='user-profile-icon' /> */}
         <img src={profilePic} alt='profile' className='user-profile-icon' />

    </div>
  )
}

export default UserBanner;