import React, { useState, useEffect } from 'react';

const ListItemCart = ({ itemObj, onIncrease, onDecrease }) => {
  const [count, setCount] = useState(itemObj.quantity);
  const [itemPrice, setItemPrice] = useState(itemObj.price * itemObj.quantity);
console.log(itemObj);
  useEffect(() => {
    // Whenever the count changes, update the item price
    setItemPrice(itemObj.price * count);
  }, [count, itemObj.price]);

  // Handle increasing the item count
  function handleCountPlus() {
    const totalcount = count + 1;
    setCount(totalcount);
    setItemPrice(itemObj.price * totalcount);
    onIncrease(itemObj.id); // Call the onIncrease function passed from the parent
  }

  // Handle decreasing the item count
  function handleCountMinus() {
    if (count > 1) {
      const totalcount = count - 1;
      setCount(totalcount);
      setItemPrice(itemObj.price * totalcount);
      onDecrease(itemObj.id); // Call the onDecrease function passed from the parent
    } else if (count === 1) {
      setCount(0);
      setItemPrice(0);
      onDecrease(itemObj.id); // Remove item if count reaches zero
    }
  }

  return (
    <>
      <div className='grid-list-menu'>
        <div className='pizza-div'>
          <img src='/img/item5.png' alt='pizza'></img>
        </div>
        <div className='item-food'>
          <h5>{itemObj.dishName}</h5>
          <p>{itemObj.dishDetail}</p>
          <div className='grid-price'>
            <p id='price'>฿{itemPrice}</p>
            <div className='price-add'>
              <span className='symbol' onClick={handleCountMinus}>-</span>
              <span className='sym-option'>{count}</span>
              <span className='symbol' onClick={handleCountPlus}>+</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListItemCart;
