import React, {useContext} from 'react'
import UserBanner from '../components/UserBanner';
import ProfileEditbtn from '../components/ProfileEditbtn';
import OrderedBy from '../components/OrderedBy';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const OrderHistory = () => {
  const auth = useContext(AuthContext);
  return (
    <>
        <UserBanner/>
        <div className='grid-profile-edit'>
        <Link to={`/profile/${auth.userId}`}>
            <ProfileEditbtn active='notactive'>
              Profile Edit
            </ProfileEditbtn>
          </Link>
          <Link to={'/orderHistory'}>
            <ProfileEditbtn active='active'>
              Order History
            </ProfileEditbtn>
          </Link>
        </div>
        <div className='grid-completed'>
            <Link to={'/orderHistory'} id='failed' >
            Completed
            </Link>
            <Link to={'/cancel'} >
           Cancelled /Failed
           </Link>
        </div>
        <OrderedBy/>
    </>
  )
}

export default OrderHistory