import React from 'react';
import OrderRoom from './OrderRoom'

const orderDetail = [
  {
    id:1,
    roomNumber:"Room 102",
    date:"05 Mar 2024",
    time:"15:20",
    price:"฿100",
    status:"Order Completed"
    },
    {
      id:2,
      roomNumber:"Room 102",
      date:"05 Mar 2024",
      time:"15:20",
      price:"฿100",
      status:"Order Cancelled"
      },
      {
        id:3,
        roomNumber:"Room 102",
        date:"05 Mar 2024",
        time:"15:20",
        price:"฿100",
        status:"Order Completed"
        },
        {
          id:4,
          roomNumber:"Room 102",
          date:"05 Mar 2024",
          time:"15:20",
          price:"฿100",
          status:"Order Completed"
          },
          {
            id:5,
            roomNumber:"Room 102",
            date:"05 Mar 2024",
            time:"15:20",
            price:"฿100",
            status:"Order Completed"
            },
            {
              id:6,
              roomNumber:"Room 102",
              date:"05 Mar 2024",
              time:"15:20",
              price:"฿100",
              status:"Order Completed"
              },
              {
                id:7,
                roomNumber:"Room 102",
                date:"05 Mar 2024",
                time:"15:20",
                price:"฿100",
                status:"Order Completed"
                }
    ]

const MerchantOrderDetail = () => {
  
  return (
    <>
        {
          orderDetail.map((order) => (
           
          <OrderRoom orderDetail ={order} key={order.id}/>))

        }
    </>
  )
}

export default MerchantOrderDetail