import React from 'react'
function RoomItem({value})  {
    return (
      <div className='room-order'>
          <div className='inner-room-flex'>
              <div>
                  <img src={value.photoname} alt={value.alt}></img>
              </div>
              <div>
                  <h4 className='r-itemname'>{value.itemName}</h4>
                  <p className='r-itempara'>{value.itemPara}</p>
              </div>
          </div>
          <div>
              <p className='r-itemamt'>{value.amt}</p>
              <h2 className='r-itemnum'>{value.num}</h2>
          </div>
      </div>
    )
  }



export default RoomItem