import React from 'react';
import MerchantBanner from '../components/MerchantBanner';
import MerchantOrderDetail from '../components/MerchantOrderDetail'

const MerchantProfileOrderHistory = () => {
  return (
    <>
    <MerchantBanner/>
    <MerchantOrderDetail/>
    </>
  )
}

export default MerchantProfileOrderHistory