import React from 'react'

const Tick = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className='backover'>
    <div className='scan-div'>
        <p className='scan-text text-align'id='ok-color-text'>Successful</p>
        <div className='tick-mark text-align'>
            <img src='/img/successful-icon.png'></img>
        </div>
        <button className='qr-back-btn' id='ok-color' onClick={onClose}>Ok</button>
    </div>
    </div>
  )
}

export default Tick