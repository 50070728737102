import React from 'react'


const AddOn = ( {optionDataObj, onOptionSelect, name }) => {
  const handleOptionSelect = (optionCost, selected, option) => {
    console.log(option);
    onOptionSelect(optionCost, selected, option);
  };
  return (
    <div className='flex-addon'>
        <form className='addon-form'>
            <input type="checkbox" id="option1" name={name} value="{optionDataObj.price}" onChange={e => handleOptionSelect(`${optionDataObj.price}`, e.target.checked, name)}/>
            <label htmlFor="option1">{optionDataObj.data1}</label>
        </form>
        <p className='price-label'>{optionDataObj.price}+</p>
        {/* <p className='line'></p> */}
       
       

    </div>
  )
}

export default AddOn