import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import  ListItem from '../components/ListItemMenu';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const Menus = [
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:99,
        photo:"/img/item1.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:100,
        photo:"/img/item3.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:30,
        photo:"/img/item2.png"
    },
     {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:55,
        photo:"/img/item4.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:70,
        photo:"/img/item6.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:85,
        photo:"/img/item5.png"
    }

]

const shop = {
        name:"Home made by A",
        description:"Get what you desire, delivered by a robot."
    }


const MenuList = () => {
    const auth = useContext(AuthContext);
    {/* SET API STARTS */}
    const [menus, setMenus] = useState([]);
    const [shop, setShop] = useState({});
    {/* SET API ENDS */}
    const [cartCount, setCartCount] = useState(0);
    const [totalCartCost, setTotalCartCost] = useState(0);
    const navigate = useNavigate();

    const shopId = useParams().shopId;

    {/* FETCH API STARTS */}
    useEffect(() => {
        const fetchMenu = async () => {
            try{
                const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_menu_by_shops/${shopId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                      }
                });
                const response = await responseData.json();
                setMenus(response.menus);
                setShop(response.shop)
                //  console.log(response.menus);
            } catch (err) {}
        };
        fetchMenu();

        const fetchCartList = async () => {
            try{
                const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_cart_list/${auth.userId}/${shopId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                      }
                });
                const response = await responseData.json();
                console.log(response);
                const cartItem = response.cart[0].carts;
                setCartCount(cartItem.length);
                setTotalCartCost(response.cart[0].totalCartCost);
                console.log('responseapi');
                console.log(response);
                // console.log(response.carts.totalCartCost);
            } catch (err) {
                console.log(err);
            }
        };
        fetchCartList();
    }, []);
    {/* FETCH API ENDS */}

    const handleAddToBasket = () => {
        navigate('/cart', { state: { userId : auth.userId, shopId: shopId } });
    }
   
    // useEffect(() => {
    //     const fetchPlace = async () => {
    //         try{
    //             const responseData = await fetch(`https://ybot-backend.salvator.app/api/shop/${shopId}`);
    //             // setLoadedPlace(responseData.place);
    //             // setFormData({
    //             //     title: {
    //             //         value: responseData.place.title,
    //             //         isValid: true
    //             //     },
    //             //     description: {
    //             //         value: responseData.place.description,
    //             //         isValid: true
    //             //     }
    //             // }, true
    //             // );
    //         } catch (err) {}

    //     }
    //     fetchPlace();
    // },[ shopId]);

  return (
    <div>
       <div className='position-fixed'>
            <div className='bg-item'>
            {/* <p className='your-bas'> */}
            <Link to={'/buy'} className='shop'>
            <img src='/img/arrow_back_white.png' alt='arrow'></img>
            </Link> 
            {/* </p> */}
                <div className='overlay'></div>
                <div className='cover-form-text'>
                    <div className='search-form'>
                        {/* <form > */}
                            {/* <input type='hidden' name='search' className='search-bar' placeholder='Search the menu'/> */}
                                {/* <img src='/img/search.png' alt='search icon' className='search-icon'></img> */}
                        {/* </form> */}
                    </div>
                    <div className='shop-flex max-shop'>
                            <div>
                            <Link to={'/buy'} className='shop'>
                                <img src={`https://ybot-backend.salvator.app/${shop.imageUrl}`} alt='icon'></img>
                            </Link> 
                            </div>
                <div className='shop-name'>
                    <h4>{shop.name}</h4>
                    <p className='menudesc'>{shop.description}</p>
                </div>
                {/* <a className='detail-btn'>Details <img src='/img/circle-right.png' alt='arrow'></img></a> */}
            </div>
           

            </div>
        </div>
      
    </div>
    

        <div className='full-list-sec'>
            {
                menus.map((menu)=> (<ListItem itemObj ={menu} key={menu.id}/>)) 
            }
        </div>

        <div className='shopping-cart-div'>
            <p className='mini-shoping-cart' onClick={() => handleAddToBasket(1)}><img src='/img/shopping-cart.png' alt='shopping-cart'></img> {cartCount && cartCount} Item</p>
            <p>฿ {totalCartCost && totalCartCost}</p>
        </div>
     </div>

  )
}

export default MenuList;