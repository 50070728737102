import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Status from '../pages/Status';

const SliderComponent = (props) => {
  // console.log('props');
  // console.log(props);
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: function(i) {
        return (
          <div className="custom-dot">
            {i === 0 ? <span></span> : i === 1 ? <span></span> : <span></span>}
          </div>
        );
      }
    };
  
    return (
      <Slider {...settings}>
        {props.token &&
        <div>
            <Status token={props.token} bridgeId={props.bridgeId} fromLocation={props.fromLocation} toLocation={props.toLocation} taskId={props.taskId} />
        
        </div>
        }
        
        <div className='slider-div'>
            <img src='/img/slider-1.jpg' alt=''/>
        </div>
        
        <div className='slider-div'>
            <img src='/img/slider-1.jpg' alt=''/>
        </div>
      </Slider>
    );
  };
  
  export default SliderComponent;


