import React from 'react'

const AllButton = ({children,color,borderRadius,border,bgcolor}) => {
  return (
    <div className='all-btn'>
        <button style={{color:color,borderRadius:borderRadius,border:border,backgroundColor:bgcolor}}>{children}</button>
   </div>
  )
}

export default AllButton