import React from 'react'
import { Link } from 'react-router-dom';

const ListItem = ({itemObj}) => {
  console.log(itemObj);
  return (
    <Link className="underline" to={`/menu-detail/${itemObj.id}`}>
    <div className='grid-list-menu'>
        <div className='pizza-div'>
            {/* <img src={itemObj.photo} alt='pizza'></img> */}
            <img src={`https://ybot-backend.salvator.app/${itemObj.photo}`} alt={itemObj.dishName}></img>
        </div>
        <div className='item-food'>
            <h5>{itemObj.dishName}</h5>
            <p>{itemObj.dishDetail}</p>
            <div className='grid-price'>
                <p id='price'>฿{itemObj.price}</p>
            </div>
        </div>

    </div>
    {/* <div className='grid-list-menu'>
        <div className='pizza-div'>
            <img src='/img/item2.png' alt='pizza'></img>
        </div>
        <div className='item-food'>
            <h5>{itemObj.menu.name}</h5>
            <p>{itemObj.menu.description}</p>
            <p id='price'>฿{itemObj.totalCost}</p>
        </div>

    </div> */}
    </Link>
  )
}

export default ListItem