import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Note = ({ totalCost, count, onCountChange, selectedOptions, menuId, shopId }) => {
    const auth = useContext(AuthContext);
    const[btn,setBtn]= useState(1);
    const navigate = useNavigate();
    // const[count,setCount] = useState(1);
    const [totalAmount, setTotalAmount] = useState(totalCost || 0);
    const [foodCost, setFoodCost] = useState(totalCost || 0); // Set initial food cost to totalCost if provided
    const [note, setNote] = useState('');
    console.log(shopId);
    const handleAddToBasket = async (number) => {
      setBtn(number);
      // Check if count is greater than 0 before adding to basket
    if (count > 0) {
      // Prepare data to send to the API
      const requestData = {
         options: selectedOptions,
         foodCost: foodCost,
         totalCost: totalAmount,
         note: note,
         menuId: menuId,
         userId: auth.userId,
         quantity: count,
         shopId: shopId
      };

      
      console.log('requestData');
      console.log(requestData);
      // Call API
      await fetch('https://ybot-backend.salvator.app/api/add_to_cart/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(requestData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to add to basket');
        }
        // Handle successful response
        navigate('/cart', { state: { userId : auth.userId, menuId: menuId, shopId: shopId } });
      })
      .catch(error => {
        console.error('Error adding to basket:', error);
        // Handle error
      });
    }
    };

    const handleNoteChange = (event) => {
      setNote(event.target.value);
    };

    useEffect(() => {
      // Update total amount when total cost prop changes
      if (totalCost !== undefined && totalCost !== totalAmount) {
        setTotalAmount(totalCost * count);
        setFoodCost(totalCost);
      }
    }, [totalCost, totalAmount, count]);

    function handleBtn(number){
        setBtn(number)
    }
    
    function handleCountPlus(){
      // setCount((count)=> count+1);
      setTotalAmount(totalAmount + foodCost);
      console.log(totalAmount);
      onCountChange(count + 1);
    }
    function handleCountMinus(){
      if(count > 0)
      // setCount((count)=> count-1);
      setTotalAmount(totalAmount - foodCost);
      console.log(totalAmount);
      onCountChange(count - 1);
    }
    
  return (
    <div>
         <div className='note-panel'>
         <p className='addextra'>Note to restaurant <span className="span-opt">Optional</span></p>
            <form>
                <input type="text" placeholder='Add your request' value={note} onChange={handleNoteChange}></input>

            </form>
          
       </div>

       
      <div className='plus-price'>
        <p className='float-total'>Total</p>
        <p className='rate'>฿{totalAmount}</p>
        <div className='flip-btn-flex'>
            <span className='flip-btn' onClick={handleCountMinus}>-</span>
            <span  className='rate'>{count}</span>
            <span className='flip-btn' onClick={handleCountPlus}>+</span>
        </div>
      </div>

      <div className='basket-btn'>
        <button className={`add-btn ${btn===1 ? "cart-active" :" "}` } onClick={() => handleAddToBasket(1)}>
            <img src='/img/cart.svg' alt='cart'  className='cart-icon'></img>
            Add to Basket
        </button>
        <button className={`add-btn ${btn===2 ? "cart-active" :" "}` } onClick={() =>handleBtn(2)} >
        <img src='/img/buy.png' alt='buy' className='cart-icon'></img>
         Pay
        </button>
      </div>

    </div>
  )
}

export default Note