import React from 'react'
import { NavLink } from 'react-router-dom'

const YbotMain = () => {
  return (
    <div className='bot-flex'>
            <div>
              <img src='/img/Ybot-fast.png'></img>
            </div>
            {/* <a href='/delivery' style={ {textDecoration: 'none'}}> */}
            <NavLink to='/delivery' style={ {textDecoration: 'none'}}>
            <div className='data-ybot'>
              <h4>Item Delivery</h4>
              <br/>
              <p>Order the robot to act as a courier for transporting items between different locations.</p>
            </div>
            </NavLink>
            {/* </a> */}
          </div>
  )
}

export default YbotMain