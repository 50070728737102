import React from 'react';
import  AllButton from './AllButton'


const Banner = () => {
  return (
    <div>
        <div className='merchant-flow-1'>
            <div className='grid-banner'>
                <div>
                    <img src='/img/order-icon.png' alt='order'></img>
                </div>
                <p>Home made Food</p>
            </div>
            <div className='grid-all-btn'>
                    <AllButton color="#fff" borderRadius="14px" border="1px solid #ABABAB" bgcolor="transparent" >New Order</AllButton>
                    <AllButton  color="#fff" borderRadius="14px" border="1px solid #ABABAB"  bgcolor="transparent">Prepare Order</AllButton>
                    <AllButton  color="#fff" borderRadius="14px" border="1px solid #ABABAB"  bgcolor="transparent">Prepare Delivery</AllButton>
                    <AllButton  color="#fff" borderRadius="14px" border="1px solid #ABABAB"  bgcolor="transparent">Delivery Process</AllButton>
                    <AllButton  color="#fff" borderRadius="14px" border="1px solid #ABABAB"  bgcolor="transparent">Delivery Done</AllButton>
            </div>

        </div>

    </div>
  )
}

export default Banner