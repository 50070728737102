import React from 'react'

const EachOrderBy = ({mark}) => {
    console.log('mark')
  return (
   <li className=''>
        <div className='grid-orderby'>
            <div className=''>
                <img src={mark.img} alt={mark.alt}/>
            </div>
            <div>
                <p className='date-time'>{mark.createdAt}</p>
                <p className='orderby-hotelname'><img src="./img/loc-hotel.png" alt='location icon'/> <span>{mark.shopName}</span></p>
                <p className='orderby-hotelname'><img src="./img/loc-room.png" alt='location icon'/> <span>{mark.location}</span></p>
            </div>
            <div>
                <p className='price'>฿{mark.totalCost}</p>
            </div>
        </div>
        <div className='grid-oc'>
            <p className='oc-text'>Order Completed</p>
            {/* <a className='oc-btn'>Reorder</a> */}
        </div>
   </li>
  )
}

export default EachOrderBy