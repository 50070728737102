import React,{ useState, useEffect, useContext } from 'react';

import YbotMain from '../components/YbotMain';
import Header from '../components/Header';
import Button from '../components/Button';
import  SliderComponent from '../components/HomeSlider';

import { useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useAuth } from "../hooks/auth-hook";
import liff from '@line/liff';

const Home = () => {
  const auth = useContext(AuthContext);
  const { profilePic } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

    const location = useLocation();
    const { state } = location;
    const [generateToken, setGenerateToken] = useState(null);
    const [bridgeUid, setBridgeUid] = useState(null);
    const [fromLocation, setFromLocation] = useState("");
    const [toLocation, setToLocation] = useState("");
    const [taskId, setTaskId] = useState();

    // use state for button
    const [activeButton, setActiveButton] = useState('Delivery');

    const handleOkClick = () => {
      setActiveButton('Delivery');
    };
    const handleCancelClick = () => {
      setActiveButton('Buy');
    };

    // useEffect(() => { 
      // let lineId;
      // const initLiff = async () => {
      //   try {
      //    let liffRes =  await liff.init({ liffId: '2003587592-89PWK0JQ' });
      //    console.log(liffRes);
      //    const loginWithLine = async () => {
      //     if (!liff.isLoggedIn()) {
      //       await liff.login({ redirectUri: `${window.location.origin}/callback` });
      //     }
      //     // Get the user's LINE ID after successful login
      //     const lineIdSub = liff.getIDToken();
      //     lineIdSub.getProfile().then((profile) => {
      //       lineId = profile;
      //     })
      //     //lineId = lineIdSub.replace('<sub>', '').replace('</sub>', '');
      //     console.log('User LINE ID:', lineId);
      //   };
      //   loginWithLine();
      //     // Your LIFF app initialization code goes here
      //   } catch (error) {
      //     console.error('LIFF initialization failed:', error);
      //   }
      // };

      // initLiff();
      
      // Function to call login API when the homepage loads
    //   const login = async () => {
    //     try {
    //       const response = await fetch('https://ybot-backend.salvator.app/api/login', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ line_id: 'line123' }),
    //       });
  
    //       if (!response.ok) {
    //         throw new Error('Failed to login ');
    //       }
    //       const data = await response.json();
    //        console.log(data);
    //       // console.log(response.token);
    //       // Call updateUser function to set the userId
    //       auth.login(data.userId, data.token);
    //       // If login successful, set isLoggedIn to true
    //       setIsLoggedIn(true);
    //     } catch (error) {
    //       // If login fails, set error message
    //       setErrorMessage('Failed to login. Please try again.');
    //     }
    //   };
  
    //   login(); // Call the login function when the component mounts
    // }, []);

    useEffect(() => {
      if (state) {
        const { token, bridgeId, fromLocation, toLocation, taskId } = state;
        // console.log('Token:', token);
        // console.log('BridgeId:', bridgeId);
        setGenerateToken(token);
        setBridgeUid(bridgeId);
        setFromLocation(fromLocation);
        setToLocation(toLocation);
        setTaskId(taskId);
        // Now you can use token and otherData as needed
        
      }
    }, [state, generateToken,bridgeUid, fromLocation, toLocation ,taskId  ]);

  return (
    <>
    {/* {isLoggedIn ? ( */}
    <div className='main-header'>
          {/* <Header image1 ="img/iocation.png" alt="image" location="YBot Delivery" image2="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" alt2="profile pic"/> */}
          <Header image1 ="img/iocation.png" alt="image" location="YBot Delivery" image2={profilePic} alt2="profile pic"/>

        <SliderComponent token={generateToken} bridgeId={bridgeUid} fromLocation={fromLocation} toLocation={toLocation} taskId={taskId} />
        <div className='grid-home-btn'>
            <Button btnText="Delivery" url="/img/buy-svg.svg" page="/" />
            <Button btnText="Order Food" url="/img/buy-svg.svg" page="/buy"/>
        </div>
        <YbotMain/>
        <div className='ad-mockup'>
          <img className='ad-banner2' src='./img/ad-1.jpg' alt='ad-banner'/>
       </div>
       {/* <div className='ad-mockup'>
          <img className ="ad-banner"src='./img/banner3.jpg' alt='ad-banner'/>
       </div> */}
      
    </div>
    {/* // ) : (
    //   // Show error message in a popup if login fails
    //   <div className="popup">
    //     <p>{errorMessage}</p>
    //   </div>
    // )} */}
    </>
  )
}

export default Home