import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AddOn  from '../components/AddOn';
import Note from '../components/Note';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const optionData = [
    {
        id: 1,
        data1:"Option 1",
        price: 15
    },
    {
        id: 2,
        data1:"Option 2",
        price: 20
    },
    {
        id: 3,
        data1:"Option 3",
        price: 15
    },
    {
        id: 4,
        data1:"Option 4",
        price: 5
    },
    {
        id: 5,
        data1:"Option 5",
        price: 10
    }
]


const MenuDetail = () => {
    const auth = useContext(AuthContext);
    const [menus, setMenus] = useState([]);
    const [shop, setShop] = useState({});
    const menuId = useParams().menuId;

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [count, setCount] = useState(1);

    const handleCountChange = newCount => {
        if (newCount >= 0) {
        setCount(newCount);
        }
    };

    const handleOptionSelect = (optionCost, selected, option) => {
        setTotalCost(prevTotalCost => {
            const newTotalCost = parseFloat(prevTotalCost) || 0;
            const costChange = parseFloat(optionCost) || 0;
            return selected ? newTotalCost + costChange : newTotalCost - costChange;
          });

          if (selected) {
            setSelectedOptions(prevSelectedOptions => [...prevSelectedOptions, option]);
          } else {
            setSelectedOptions(prevSelectedOptions => prevSelectedOptions.filter(item => item !== option));
          }
    };



{/* FETCH API STARTS */}
    useEffect(() => {
        const fetchMenu = async () => {
            try{
                const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_menu_detail/${menuId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                      }
                });
                const response = await responseData.json();
                setMenus(response.menus);
                setShop(response.shop)
                //   console.log(response.shop);
            } catch (err) {}
        };
        fetchMenu();
    }, []);
{/* FETCH API ENDS */}
  return (
    <div className='option-width'>
        <div >
        <Link to={`/menu-list/${shop._id}`} className='shop'>
            <img src='/img/bg-item.png' alt="food image" className='banner'></img>
        </Link> 
        </div>
        <div className='food-varity'>
            <div className='food-prod'>
                {/* <h4>Fried rice with tomato Sauce</h4>
                <p>฿ 85</p> */}
                {/* Update API ENDS */}
                <h4>{menus.dishName}</h4>
                <p>฿ {menus.price}</p>
                {/* Update API ENDS */}
            </div>
            <p className='food-product-text'>{menus.dishDetail}</p>
        </div>
        <p className='addextra'>Add Extra <span className="span-opt">Optional</span></p>
        {
            optionData.map((option, index) => (<AddOn optionDataObj ={option} key={option.id} name={`option${index + 1}`}   onOptionSelect={handleOptionSelect} />))
        }
      
      <Note totalCost={Number(totalCost) + Number(menus.price)} count={count} onCountChange={handleCountChange} selectedOptions={selectedOptions} menuId={menuId} shopId={shop._id} />
      
     
        

    </div>
  )
}

export default MenuDetail