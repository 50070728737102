import React from 'react'

const RoomNumber = () => {
  return (
    <div className='flex-room'>
        <div>
            <p className='room-no'>Room 102 </p>
            <p className='d-t'>order Time: 15:45 | 05/Mar/2024</p>
        </div>
        <button className='cc-btn'>Contact customer</button>
    </div>
  )
}

export default RoomNumber