import React from 'react';
import Banner from '../components/Banner';
import RoomData from '../components/RoomData';
import RoomNumber from '../components/RoomNumber';
import ButtonMerchant from '../components/ButtonMerchant'

const MerchantNewOrder = () => {
  return (
    <div className='merchant'>
        <Banner/>
        <RoomNumber/>
        <RoomData/>
        <ButtonMerchant/>
    </div>
  )
}

export default MerchantNewOrder