import React from 'react'

const DoneButton = () => {
  return (
    <div className='robot-no-pas'>
        <div className='y-no-div1'>
            <p className='rr-num'>Robot No:</p>
            <p className='y-no1'>YBot 01</p>
        </div>
        <div className='y-no-div2'>
            <p className='rr-num'>Collect Item Password:</p>
            <p className='y-no2'>#12345</p>
        </div>
    </div>
    // <div>
    //      <button className='acc-btn' id="width-done">Done</button>
    // </div>
  )
}

export default DoneButton