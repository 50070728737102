import React from 'react'

const AddBanner = () => {
  return (
    <div className='banner-ad'>
      <img src='/img/ad.jpg' alt='ad image' height="100%" width="100%" />
    </div>
  )
}

export default AddBanner