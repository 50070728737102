import React from 'react'

const BoBanner2 = () => {
  return (
    <>
        <header className='shop-owner-custo'>
            <div className='bo-top-banner'>
                <img src='./img/buildingowner/banner.png' alt='logo'/>
            </div>
            <div className='top-shop-name'>
                <div className='shop-name-bo'>
                    <img src='./img/buildingowner/banner-logo.png' alt='logo'/>
                    <h3>burj khalifa</h3>
                </div>
                <div className='serch-setting'>
                    <img src='./img/search.png' alt='search'/>
                    <img src='./img/buildingowner/settings.png' alt='search'/>
                </div>
            </div>
            {/* <section className='personal-shop-detail'>
                <div className='aside-header'>
                    <div className='shop-name-detail'>
                        <div className=''>
                            <img src='./img/buildingowner/logo7.png' alt='logo'/>
                        </div>
                        <div className='starbucks'>
                            <h4>Starbucks</h4>
                            <p>Today 10 Delivered orders. </p>
                        </div>
                    </div>
                    <a href='#'><img src='./img/buildingowner/icon.png' alt='icon'/></a>
                </div>
            </section> */}
            
        </header>

    </>
  )
}

export default BoBanner2