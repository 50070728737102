import React, { useEffect, useContext, useState } from 'react'
import EachOrderBy from './EachOrderBy';
// import EachOrderBy from './EachOrderBy'
import { AuthContext } from '../context/AuthContext';

const order = [
    {
        image:"./img/order-icon.png",
        hotelName:"Home made by A",
        roomNumber:"Room 102",
        alt:"hotel icon",
        date:"05 Mar 2024",
        time:"15:45",
        price:100
    },
    {
        image:"./img/order-icon.png",
        hotelName:"Indian cafe",
        roomNumber:"Room 102",
        alt:"hotel icon",
        date:"05 Mar 2024",
        time:"15:45",
        price:150
    },
    {
        image:"./img/order-icon.png",
        hotelName:"MK restarant",
        roomNumber:"Room 96",
        alt:"hotel icon",
        date:"05 Mar 2024",
        time:"15:45",
        price:200
    },
    {
        image:"./img/order-icon.png",
        hotelName:"Fat Cow",
        roomNumber:"Room 1051",
        alt:"hotel icon",
        date:"05 Mar 2024",
        time:"15:45",
        price:500
    }
];


const OrderedBy = () => {
    const auth = useContext(AuthContext);
    let [data, setData] = useState([]);

    useEffect(() => {
        const fetchOrder = async() => {
         try{
          let datas =  await fetch(`https://ybot-backend.salvator.app/api/get_order_data/${auth.userId}/deliveryCompleted`)
          let orderData = await datas.json();
          setData(orderData.orderData);
        } catch (err) {
            console.log(err);
        }
        }
        fetchOrder()
    }, [])

     console.log(data)
  return (
   <ul>
    {
                data.map((ce) => (
                    <EachOrderBy mark={ce}/>
                ))
            }
   </ul>
    )
}

export default OrderedBy