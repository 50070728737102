// src/App.js
import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import liff from '@line/liff';
import Delivery from './pages/Delivery';
import Home from './pages/Home';
import Buy from './pages/Buy';
import MenuList from './pages/MenuList';
import MenuDetail from './pages/MenuDetail';
import QrCode from './components/QrCode';
import Tick from './components/Tick';
import Cart from './pages/Cart';
import Popup from "./components/Popup";
import MerchantNewOrder from './pages/MerchantNewOrder';
import MerchantPrepareOrder from './pages/MerchantPrepareOrder';
import MerchantPrepareDelivery from './pages/MerchantPrepareDelivery';
import UserProfileEdit from './pages/UserProfileEdit';
import OrderHistory from "./pages/OrderHistory";
import Cancel from "./components/Cancel";
import HistoryDetail from './components/HistoryDetail';
import MerchantProfileEdit1 from './pages/MerchantProfileEdit1';
import MerchantProfileOrderHistory from './pages/MerchantProfileOrderHistory';
import MerchantProfileEdit2 from './pages/MerchantProfileEdit2';
import BuildingOwnerPage1 from './pages/BuildingOwnerPage1';
import BuildingOwnerPage2 from './pages/BuildingOwnerPage2';
import Login from "./pages/Login";
import { AuthContext } from "./context/AuthContext";
import AuthHandler from './AuthHandler';
import { useAuth } from "./hooks/auth-hook";

const App = () => {
  const auth = useContext(AuthContext);
  const { isLoggedIn, token, login, logout, userId } = useAuth();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [profile, setProfile] = useState(null);
  console.log('App auth');
    console.log(auth);
  return (
    <Routes>
      {auth.isLoggedIn ? (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/delivery" element={
          <Delivery />
          } />
          <Route path="/delivery-status" element={
          <Home />
          } />
          <Route path="/buy" element={
          <Buy />
          } />

          <Route path="/menu-list/:shopId" element={
          <MenuList />
          } />
          <Route path="/menu-detail/:menuId" element={
          <MenuDetail />
          } />
          <Route path="/qr-code" element={
          <QrCode />
          } />
          <Route path="/tick" element={
          <Tick />
          } />
          <Route path="/cart" element={
          <Cart />
          } />
          <Route path="/popup" element={
          <Popup />
          } />
          <Route path="/merchant-new-order" element={
          <MerchantNewOrder />
          } />
          <Route path="/merchant-prepare-order" element={
          <MerchantPrepareOrder />
          } />
          <Route path="/merchant-prepare-delivery" element={
          <MerchantPrepareDelivery />
          } />
          <Route path="/profile/:userId" element={
          <UserProfileEdit />
          } />
          <Route path="/orderhistory" element={
          <OrderHistory />
          } />
          <Route path="/cancel" element={
          <Cancel />
          } />
          <Route path="/historydetail" element={
          <HistoryDetail />
          } />
          <Route path="/merchant-profile-edit" element={
          <MerchantProfileEdit1 />
          } />
          <Route path="/merchant-profile-edit1" element={
          <MerchantProfileOrderHistory />
          } />
          <Route path="/merchant-profile-edit2" element={
          <MerchantProfileEdit2 />
          } />
          <Route path="/page1" element={
          <BuildingOwnerPage1 />
          } />
          <Route path="/page2" element={
          <BuildingOwnerPage2 />
          } />
          <Route path="/register" element={
          <Login />
          } />
        </>
      ) : (
        <>
        <Route path="/register" element={<Login />} />
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="*" element={<Popup message={errorMessage} />} /> */}
        </>
      )}
    </Routes>
  );
};

const MainApp = () => {
  return (
    <>
      <AuthHandler />
      <App />
    </>
  );
};

export default MainApp;
