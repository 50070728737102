import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({btnText,url,onSelect, page}) => {
  return (
    <Link className={'homeBtn'} to={page}>
    <button  onClick={onSelect} className='button-default'  >
        <img style={{width:"3rem"}} src={url}  alt='buyimage'/>
        <span>{btnText}</span>
    </button>
    </Link>
  )
}

export default Button