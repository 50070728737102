import React from 'react';
import UserBanner  from '../components/UserBanner';

const HistoryDetail = () => {
  return (
    <>
     <UserBanner/>
     <section>
        
     </section>
    </>
  )
}

export default HistoryDetail