import React from 'react'

const MerchantOrderDetailEdit2 = () => {
  return (
    <>

    <section className='relative-top'>

        <div className='order-romm-flex '>
            <div className='inner-room-flex'>
                <div>
                    <img className="loc-icon" src='./img/iocation.png'  alt='location icon'/>
                </div>
                <div className='revise-div'>
                    <p className='r-text-room'>Room 102</p>
                    <p className='r-text-date'><span>25 Mar 2024</span>  <span>15:45</span></p>
                </div>
            </div>
            <div className='status-div'>
                <p className='r-text-price'>฿200</p>
                <p className='r-text-status'>Order Completed</p>
            </div>
        </div>

       
         <div className='room-order'>
          <div className='inner-room-flex'>
              <div>
                  <img src='./img/hotel1.png' alt=""></img>
              </div>
              <div>
                  <h4 className='r-itemname'>Spicy Noodles</h4>
                  <p className='r-itempara'>฿200</p>
              </div>
          </div>
          <div>
              <p className='r-itemamt'>Amount</p>
              <h2 className='r-itemnum'>1</h2>
          </div>
      </div>
      <div className='room-order'>
          <div className='inner-room-flex'>
              <div>
                  <img src='./img/hotel2.png' alt=""></img>
              </div>
              <div>
                  <h4 className='r-itemname'>Thomiyam khung</h4>
                  <p className='r-itempara'>฿100</p>
              </div>
          </div>
          <div>
              <p className='r-itemamt'>Amount</p>
              <h2 className='r-itemnum'>1</h2>
          </div>
      </div>
      <div className='room-order'>
          <div className='inner-room-flex'>
              <div>
                  <img src='./img/hotel3.png' alt=""></img>
              </div>
              <div>
                  <h4 className='r-itemname'>Thai rice</h4>
                  <p className='r-itempara'>200</p>
              </div>
          </div>
          <div>
              <p className='r-itemamt'>Amount</p>
              <h2 className='r-itemnum'>2</h2>
          </div>
      </div>

      <button className='voh-btn'>Back</button>
      </section>
    </>
  )
}

export default MerchantOrderDetailEdit2
