import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import  ListItemCart from '../components/ListItemCart';
import Tick from '../components/Tick';
import { Link, useNavigate } from 'react-router-dom';
import QrCode from '../components/QrCode';
import { AuthContext } from '../context/AuthContext';

const Menus = [
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:99,
        photo:"/img/item1.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:100,
        photo:"/img/item3.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:30,
        photo:"/img/item2.png"
    },
     {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:55,
        photo:"/img/item4.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:70,
        photo:"/img/item6.png"
    },
    {
        dishName:"Spicy Noodle",
        dishDetail:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        price:85,
        photo:"/img/item5.png"
    }

]

const Cart = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [cart, setCart] = useState([]);
    const [totalCartCost, setTotalCartCost] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [payment, setPayment] = useState(false);
    const [removeCart, setRemoveCart] = useState(false);
  
    const location = useLocation();
    const { state } = location;
    const userId = state.userId;
    const shopId = state.shopId;
  
    // Fetch cart data
    useEffect(() => {
      const fetchMenu = async () => {
        try {
          const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_cart_list/${userId}/${shopId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              }
          });
          const response = await responseData.json();
          setCart(response.cart[0].carts);
            console.log(response);
          setTotalCartCost(response.cart[0].totalCartCost);
        } catch (err) {
          console.log(err);
        }
      };
      fetchMenu();
    }, [userId, shopId]);
  
    // Handle increase in quantity
    const handleIncrease = async (itemId) => {
        const updatedCart = cart.map((item) => {
          if (item.id === itemId) {
            item.quantity += 1;
            item.totalCost = item.quantity * item.foodCost; // Update the totalCost of the item
          }
          return item;
        });
        
        setCart(updatedCart);  // Update the state with the new cart
        calculateTotal(updatedCart);  // Recalculate the overall total
        const updatedItem = updatedCart.find((item) => item.id === itemId);
        
        // Call the backend API with the new quantity and totalCost of the item
        await updateCartItemInBackend(userId, itemId, updatedItem.quantity, updatedItem.totalCost, shopId);
      };
      
      // Handle decrease in quantity
      const handleDecrease = async (itemId) => {
        const updatedCart = cart.map((item) => {
          if (item.id === itemId && item.quantity > 0) {
            item.quantity -= 1;
            item.totalCost = item.quantity * item.foodCost; // Update the totalCost of the item
          }
          return item;
        });
      
        const updatedItem = updatedCart.find((item) => item.id === itemId);
      
        if (updatedItem.quantity === 0) {
          await deleteCartItem(itemId);  // Remove from backend if the quantity reaches 0
          setCart(updatedCart.filter((item) => item.id !== itemId));  // Remove the item from cart state
        } else {
          setCart(updatedCart);  // Update cart state with new quantities
          await updateCartItemInBackend(userId, itemId, updatedItem.quantity, updatedItem.totalCost, shopId);  // Update backend
        }
      
        calculateTotal(updatedCart);  // Recalculate the overall total
      };
      
      // Update item quantity and totalCost in backend
      const updateCartItemInBackend = async (userId, itemId, quantity, totalCost, shopId) => {
        try {
          await fetch(`https://ybot-backend.salvator.app/api/update_cart_item`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            },
            body: JSON.stringify({ userId, itemId, quantity, totalCost, shopId }), // Send quantity and totalCost
          });
        } catch (err) {
          console.log('Error updating cart item in backend', err);
        }
      };
      
      // Calculate the total cost for the entire cart
      const calculateTotal = (updatedCart) => {
        const total = updatedCart.reduce((acc, item) => acc + item.totalCost, 0);
        setTotalCartCost(total);  // Update the total cost state
      };
  
    // Delete item from cart in backend
    const deleteCartItem = async (itemId) => {
        try {
          await fetch(`https://ybot-backend.salvator.app/api/delete_cart_item`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            },
            body: JSON.stringify({ userId, itemId, shopId }),
          });
        } catch (err) {
          console.log('Error deleting cart item from backend', err);
        }
      };
  
    // Handle payment logic (unchanged)
    const handlePayment = () => {
      setPayment(true);
      setShowQR(true);
    };
  
    const handleQR = () => {
      setShowQR(false);
      setShowModal(true);
      setRemoveCart(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
      navigate('/');
    };
  
    // Save order and remove cart (unchanged)
    useEffect(() => {
      if (payment) {
        const saveOrder = async () => {
          try {
            const responseData = await fetch(`https://ybot-backend.salvator.app/api/save_order`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              },
              body: JSON.stringify({
                userId: userId,
                items: cart,
                shopId: shopId,
                totalCost: totalCartCost,
              }),
            });
            const response = await responseData.json();
            setShowModal(true);
          } catch (err) {
            console.log(err);
          }
        };
        saveOrder();
      }
    }, [payment]);
  
    useEffect(() => {
      if (removeCart) {
        const deleteCart = async () => {
          try {
            await fetch(`https://ybot-backend.salvator.app/api/delete_cart/${userId}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              }
            });
            setRemoveCart(false);
          } catch (err) {
            console.log('Error removing cart', err);
          }
        };
        deleteCart();
      }
    }, [removeCart]);
  
    return (
      <div className='page-8'>
        <p className='your-bas'>
          <Link to={`/menu-list/${shopId}`}>
            <img src='/img/arrow_back_ios.png' alt="back"></img>
          </Link>
          Your Basket
        </p>
        <div className='full-list-sec-2'>
          {cart.map((item) => (
            <ListItemCart 
              key={item.id} 
              itemObj={item} 
              onIncrease={() => handleIncrease(item.id)} 
              onDecrease={() => handleDecrease(item.id)} 
            />
          ))}
        </div>
        <div className='green-total'>
          <div className='flex-btn-price'>
            <p className='green-text'>฿{totalCartCost}</p>
            <button className='buy-price-btn' onClick={handlePayment}>
              <img src='/img/buy.png' alt='buy icon'></img>
              <span>Pay</span>
            </button>
          </div>
        </div>
        <QrCode isOpen={showQR} onClose={handleQR} />
        <Tick isOpen={showModal} onClose={handleCloseModal} />
      </div>
    );
  };
  

export default Cart