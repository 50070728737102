import React from 'react'
import Banner from '../components/Banner'
import  Room from '../components/Room'

const MerchantPrepareDelivery = () => {
  return (
    <div className='merchant'>
        <Banner/>
        <Room/>
        {/* <Room/> */}

    </div>
  )
}

export default MerchantPrepareDelivery