import React from 'react'

const QrCode = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className='scan-div text-align'>
        <p className='scan-text'>Scan to pay</p>
        <div className='qr-pay'>
            <img src='./img/qr_code.png'></img>
        </div>
        <button className='qr-back-btn' onClick={onClose}>Done</button>
    </div>
  )
}

export default QrCode