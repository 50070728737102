import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import AddBanner from '../components/AddBanner';
import { IoIosArrowDown } from "react-icons/io";

import { useNavigate } from 'react-router-dom';
import { useAuth } from "../hooks/auth-hook";

const Delivery = () => {  
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [bridgeId, setBridgeId] = useState(null);
    const [placeItemCode, setPlaceItemCode] = useState(null);
    const [pickupCode, setPickupCode] = useState(null);
    const [startPoint, setStartPoint] = useState(null);
    let [locationList, setLocationList] = useState([]);
    let [startPoi, setStartPoi] = useState();
    let [targetPoi, setTargetPoi] = useState();
    let [fromLocation, setFromLocation] = useState("");
    let [toLocation, setToLocation] = useState("");
    let [taskId, setTaskId] = useState();
    const { profilePic } = useAuth();
    
    useEffect(() => {
      let fetchLocation = async () => {

        const tokenResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/auth/token',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "app_id": "SalvatorTech",
            "app_secret": "324d5a3e0efa7629b42d5869b222261c"
          })
            }
          );
          const response = await tokenResponse.json();
          const generatedToken =  response.data.access_token;
            // console.log(generatedToken);
          // Store the token
          setToken(generatedToken);
          

        const locationList = await fetch('https://api.yogorobot.com/app-bridge/api/v1/poi?site_uid=1230', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + generatedToken
          },
        });
        
        const locationListResult = await locationList.json();
        const locations = locationListResult.data.interest;
        // console.log('locationListResult');
          // console.log(locations);
         setLocationList(locations);
      }

      fetchLocation();
      
    }, [token]);

    const handleReserveClick = async () => {
        // console.log('responseData');
        try {
          //! First API call to generate token
          // Get token API
          const tokenResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/auth/token',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "app_id": "SalvatorTech",
            "app_secret": "324d5a3e0efa7629b42d5869b222261c"
          })
            }
          );
          const response = await tokenResponse.json();
          const generatedToken =  response.data.access_token;
          //  console.log(generatedToken);
          // Store the token
          setToken(generatedToken);
           //console.log(token);

          if(generatedToken){
           //! Second API call with the generated token and other information
          //  Place an order for delivery (3-digit code) API
          const secondApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task/delivery/reserved?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization : 'Bearer ' + generatedToken
            },
            body: JSON.stringify({
              "start_poi" : startPoi,
              "target_poi" : targetPoi,
              "task_external_id" : "ext310101",
              "sender_info" : {
                  "user_name" : "sekar",
                  "user_phone" : "0655210888"
              },
              "receiver_info" : {
                  "user_name" : "Test",
                  "user_phone" : "0655210999"
              },
              "pickup_timeout": 10  
            })
           
          });
          
          const secondApiResult = await secondApiResponse.json();
          const bridgeLinkUid = secondApiResult.data.bridge_link_uid;
          setBridgeId(bridgeLinkUid);
           //console.log(secondApiResult);

          if(bridgeLinkUid){
          //! Third API call with the generated token and other information
          // Query bridge tasks API
          const thirdApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization : 'Bearer ' + generatedToken
            },
            body: JSON.stringify({
              "bridge_link_uids" : [bridgeLinkUid]
            })
            
          });
          
          const thirdApiResult = await thirdApiResponse.json();
          const placeItemCode = thirdApiResult.data[0].dispatch_info.logical_code;
          const pickupCode = thirdApiResult.data[0].dispatch_info.pickup_code;
          const startPoint = thirdApiResult.data[0].tasks[0].start_poi;
          const apiTaskId = thirdApiResult.data[0].tasks[0].task_id;
          // console.log('thirdApiResult');
          //  console.log(thirdApiResult.data[0]);
          // console.log(startPoint);
          setPlaceItemCode(placeItemCode);
          setPickupCode(pickupCode);
          setStartPoint(startPoint);
          setTaskId(apiTaskId);
          // console.log('apiTaskId');
          // console.log(apiTaskId);
          if(startPoint){
          //! Fourth API call with the generated token and other information
          //? Uncomment this to move the robot --- starts
          // Summon of Robot API
         
          const fourthApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task/delivery/summon?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization : 'Bearer ' + generatedToken
            },
            body: JSON.stringify({
              "target_poi" : startPoint,
              "task_external_id": "ext310101",
              "duration": 10
            })
          });
          
          const fourthApiResult = await fourthApiResponse.json();

          // console.log('fourthApiResult');
          // console.log(fourthApiResult);
           const summonBridgeId = fourthApiResult.data.bridge_link_uid;
          // console.log(taskCode);
          //? Uncomment this to move the robot --- ends

          //? Comment this after uncomment the above one --- starts
          // const fourthApiResult = true;
          // const summonBridgeId = apiTaskId;
          //? Comment this after uncomment the above one --- ends
          
          if(fourthApiResult)
          navigate('/delivery-status', { state: { token : generatedToken, bridgeId : bridgeLinkUid,fromLocation : fromLocation, toLocation: toLocation, taskId: summonBridgeId  } });
        }
        }
        }

        } catch (error) {
          console.error('Error:', error);
        }
      };


      let handleFromLocation = (e) => {
        // console.log(e.target.selectedOptions[0].text);
        setFromLocation(e.target.selectedOptions[0].text);
        setStartPoi(e.target.value);
      }

      let handleToLocation = (e) => {
        setToLocation(e.target.selectedOptions[0].text);
        setTargetPoi(e.target.value);
      }


  return (
    <section className='deli-wrap-sec'>
    <div className='page2-main'>
         <Header image1 ="img/point-right.png" alt="image" location="Delivery Setup" image2={profilePic} alt2="profile pic"/>
        {/* <div className='space-flex'> 
                <div>
                    <img src='/img/point-right.png' alt='arrow'></img>
                    <span>Delivery Setup</span>
                </div>
     
                <div className='sal-log'>
                    <img src='/img/sal-logo.png' alt='salvator log'></img>
                </div>
        </div> */}
        {/* section2 */}
        <section>
            <div className='location-div'>
                <img src='/img/icon-setting.png' alt="location icon"/>
                <p className='para-location'>ตั้งค่าจุดหมาย และ ปลายทาง สำหรับ รับ - ส่งภัสดุ</p>
            </div>
           
        </section>
        {/* section3 */}
        <section className='location-section'>
        
      
            <div className='location-dropdown'>
            <div className='top-grid-location'>
                    <div>
                        <img src='/img/from-to-line.png' alt="travel line"></img>
                    </div>
                  
                <div className='location-grid-2'>   
                  
                 
                    <div className='grid-location'>
                        {/* <div className='number'>
                            <div className="s">1</div>
                            <div className="line"></div>
                        </div> */}
                
                        <p className='from-text'>From</p>
                        <div className='robo-no-div'>
                            {/* <p className='from-text'>Lobby <span>YBot No.12345678</span></p> */}
                            <select name="fromLocation" onChange={handleFromLocation} value={startPoi} className='loc-sel' > 
                           
                            <option>From Location</option>
                              {locationList.map((loc) => {
                               return  <option key={loc.group_poi.poi_sid ? loc.group_poi.poi_sid : ''} value={loc.group_poi.poi_sid ? loc.group_poi.poi_sid : '' }>{loc.name}</option>
                              })}
                            </select>
                            {/* <p className='robo-number'>YBot No.12345678</p> */}
                            {/* <img className='right-arrow' src='/img/circle-new.png' alt='icon'></img> */}
                        </div>
                    </div>
                        <div className='grid-location-no'>
                            {/* <div className='number'>
                                <div className="sec-grid s">2</div>
                                <div className="line"></div>
                            </div> */}
                            <div className='sec-grid-height'></div>
                            <div className='sec-grid-height'></div>
                            <div className='sec-grid-height'></div>
                        </div>

                    {/* 3 */}
                    <div className='grid-location' id="move-center">
                        {/* <div className='number'>
                            <div className="s">3</div> 
                          <div className="line"></div>
                            
                        </div> */}
                        <p className='from-text'>To</p>
                        <div className='robo-no-div'>
                            {/* <p className='from-text'>Room102 <span>YBot No.12345678</span></p> */}
                            <select name="toLocation" onChange={handleToLocation} value={targetPoi} className='loc-sel'>
                              <option>To Location</option>
                              {locationList.map((loc) => {
                               return  <option key={loc.group_poi.poi_sid ? loc.group_poi.poi_sid : '' } value={loc.group_poi.poi_sid ? loc.group_poi.poi_sid : '' }>{loc.name}</option>
                              })}
                            </select>
                            {/* <p className='robo-number'>YBot No.12345678</p> */}
                            {/* <img className='right-arrow' src='/img/circle-new.png' alt='icon'></img> */}
                        </div>
                    </div>
                    </div>
                </div>
              

            </div>
           
        </section>
        {/* <ButtonReserve/> */}

        
        {/* <ButtonReserve bgColor="#01CC91" text="Reserve" color="#fff" borderRadius="10px" width="200px" onclick={handleReserveClick} /> */}
        {/* <button className='reserved-btn' style={{backgroundColor:'rgb(1, 204, 145)',color:'#fff',borderRadius:'10px'}} onClick={handleReserveClick}>Reserve</button> */}
        <button className='reserved-btn' style={{backgroundColor:'rgb(1, 204, 145)',color:'#fff',borderRadius:'10px'}} onClick={handleReserveClick}>Reserve</button>
        <div className='ad-mockup'>
          <img className='ad-banner2' src='./img/ad-3.jpg' alt='ad-banner'/>
       </div>
       {/* <AddBanner/> */}
    </div>
    </section>
  )
}

export default Delivery;