import React from 'react';
import  RoomNumber from './RoomNumber';
import  RoomData from './RoomData'
//  import Button from './Button';
import DoneButton from './DoneButton';
// import YbotButton from './YbotButton'

const Room = () => {
  return (
    <div className='overall-room'>
        <RoomNumber/>
        < RoomData/>
        {/* < RoomData/>  */}
        {/* <Button/> */}
        <DoneButton/>
        {/* <YbotButton/> */}
       
    </div>
  )
}

export default Room