import React from 'react'



const MerchantBanner = () => {
  return (
    <>
    <section>
        <header>
            <div className='merchant-edit-banner'>
                <img className="banner-mer" src='./img/merchant/banner.jpg' alt='banner'/>
                <div className='shop-logo-div'>
                    <img className="shop-logo" src='./img/merchant/shop-logo.png' alt='logo'/>
                    <img className='camera-icon' src='./img/merchant/cameraicon.png' alt='camera icon'/>
                </div>
                <img className='camera-icon-2' src='./img/merchant/cameraicon.png' alt='camera icon'/>
            </div>
                 <img className='previous-arrow' src='./img/arrow_back.png' alt='back-icon'/>
           
        </header>

       
    </section>

    </>
  )
}

export default MerchantBanner