import React from 'react'
import { Link } from 'react-router-dom';

const Order = (props) => {
  return (
    <div >
        <div className='cover-shop'>
            <div className='shop-flex'>
                <div>
                    <img src={`https://ybot-backend.salvator.app/${props.image}`} alt='icon'></img>
                </div>
                <div className='shop-name'>
                    <div className='detail-flex'>
                        <h4>{props.hotel}</h4>
                        <Link to={`/menu-list/${props.id}`} className='detail-btn'>Details <img src='/img/circle-right.png' alt='arrow'></img></Link>
                    </div>

                    <p>{props.data}</p>
                </div>
                {/* <Link to={`/food-list/${props.id}`} className='detail-btn'>Details <img src='/img/circle-right.png' alt='arrow'></img></Link> */}
            </div>
            {/* 2 */}
            <div className='grid-items'>
            {props.menus.length > 0 && props.menus.map(menu => (
                <img src={`https://ybot-backend.salvator.app/${menu}`} alt='food item'></img>    
            ))}
            </div>
        </div>
    </div>
  )
}

export default Order