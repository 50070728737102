import React from 'react'

const shoper = [
  {
    order:5,
    picture:"./img/buildingowner/customer1.png",
    room:"Room 101",
    name:"Sekar"
  },
  {
    order:2,
    picture:"./img/buildingowner/customer2.png",
    room:"Room 102",
    name:"Sekar"
  },
  {
    order:1,
    picture:"./img/buildingowner/customer3.png",
    room:"Room 103",
    name:"Sekar"
  },
  {
    order:2,
    picture:"./img/buildingowner/customer4.png",
    room:"Room 101",
    name:"Sekar"
  },
  {
    order:4,
    picture:"./img/buildingowner/customer1.png",
    room:"Room 102",
    name:"Sekar"
  },
  {
    order:1,
    picture:"./img/buildingowner/customer1.png",
    room:"Room 106",
    name:"Sekar"
  }
]

const ShoperDetail = () => {
  return (
    <>
    <section className='sh-section'>
          <section className='personal-shop-detail'>
                <div className='aside-header'>
                    <div className='shop-name-detail'>
                        <div className=''>
                            <img src='./img/buildingowner/logo7.png' alt='logo'/>
                        </div>
                        <div className='starbucks'>
                            <h4>Starbucks</h4>
                            <p>Today 10 Delivered orders. </p>
                        </div>
                    </div>
                    <a href='#'><img src='./img/buildingowner/icon.png' alt='icon'/></a>
                </div>
            </section>

          <div className='date-wrap-div'>
              <div className='date-div'>
                <p className='filter-icon'><img id="f-icon" src='./img/buildingowner/filter_list.png' alt='filter logo'/> <span className='filter-text'>Filter</span></p>
                <p>
                  <input type='date'/>
                </p>
              </div>
            </div>
    {
         shoper.map((person)=>{
          console.log(person);
          let{order,picture,room,name}= person

          return(
            <div className='wrapper-test'>
              <div className='grid-person'>
                  <div className='shoper-flex-list'>
                      <p className='filter-item'>{order}<img src='./img/buildingowner/route.png' alt='icon'/></p>
                      <div className='shoper-test'>
                          <img src={picture} alt="user"/>
                          <div className='shoper-list'>
                            <p className='shoper-room'>{room}</p>
                            <p className='shoper-name'>{name}</p>
                          </div>
                    </div>
                </div>
                <p className='detatil-grid'>Details <img src='./img/buildingowner/arrow.png' alt='icon'/></p>
                </div>
            </div>
          )
          
    
        })

    }
    </section>
 
    </>
  )
}

export default ShoperDetail