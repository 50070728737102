import React from 'react'

const PrepareDoneButton = () => {
  return (
    <div className='doneBtn'>
         <button className='acc-btn' id="width-done">Done</button>
    </div>
  )
}

export default PrepareDoneButton