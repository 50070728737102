import React from 'react';
import BuildingBanner from '../components/BuildingBanner';
import ShopList from '../components/ShopList'

const BuildingOwnerPage1 = () => {
  return (
    <>
        <section className='building-owner'>
          <BuildingBanner/>
          <ShopList/>
       </section>
 
    
    </>
  )
}

export default BuildingOwnerPage1