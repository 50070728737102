import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import Tick from './Tick';
import { AuthContext } from '../context/AuthContext';

const UserForm = () => {
    const auth = useContext(AuthContext);
    let [user,setUser] = useState({});
    const [showModal, setShowModal] = useState(false);
    let [inputValue, setInputValue] = useState({
        name: "",
        mobile: "",
        email: "",
        dob: "",
        room_no: "",
        id: ""
    });

    let {name, mobile, email, dob, room_no, id} = inputValue;
    
    let [formSubmit, setFormSubmit] = useState(false);

    let userId = useParams().userId;

    useEffect(() => {
        const fetchUser = async () => {
            try{
                const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_profile/${userId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                      }
                });
                if (!responseData.ok) {
                 throw new Error('Failed to fetch shops');
                }
                const response = await responseData.json();
                console.log(response);
                setUser(response.user);
                setInputValue({
                    name: response.user.name,
                    mobile: response.user.mobile,
                    email: response.user.email,
                    dob: response.user.dob,
                    room_no: response.user.room_no,
                    id: userId
                })
            } catch (err) {}
        };
        fetchUser();
    }, []);

    let handleSubmit = async () => {
        await fetch('https://ybot-backend.salvator.app/api/update_profile/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            },
            body: JSON.stringify(inputValue)
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to add to basket');
            }
            setFormSubmit(false);
            setShowModal(true);
          })
          .catch(error => {
            console.error('Error adding to basket:', error);
          });
    }

    useEffect(() => {
        console.log(user);
        if(formSubmit){
            handleSubmit();
        }
    }, [formSubmit])

    let handleButtonClick = (e) => {
        e.preventDefault();
        setFormSubmit(true);
    }

    let handleInput = (e) => {
        let {name, value} = e.target;
        setInputValue({...inputValue, [name]: value})
    }  
    
    const handleCloseModal = () => {
        setShowModal(false);
      };

  return (
    <>
    <form className='user-profile-form' onSubmit={handleButtonClick}>
        <div className='user-profile'>
            <img src='/img/account_circle.png' alt="profile-icon"/>
            <input type='text' placeholder='Enter your name' name='name' onChange={handleInput} value={name}/>
        </div>
        <div className='user-profile'>
            <img src='/img/call.png' alt="call-icon"/>
            <input type='number' placeholder='Enter your Mobile number' name='mobile' onChange={handleInput} value={mobile}/>
        </div>
        <div className='user-profile'>
            <img src='/img/mail.png' alt="profile-icon"/>
            <input type='email' placeholder='Enter your mail' name='email' onChange={handleInput} value={email}/>
        </div>
        <div className='user-profile'>
            <img src='/img/cake.png' alt="profile-icon"/>
            <input type='date' placeholder='DOB' name='dob' onChange={handleInput} value={dob}/>
        </div>
        <div className='user-profile'>
            <img src='/img/location_on.png' alt="profile-icon"/>
            <input type='text' placeholder='Room number' name='room_no' onChange={handleInput} value={room_no}/>
        </div>
        <div>
            <button className='reserved-btn' type='submit' style={{backgroundColor:'rgb(1, 204, 145)',color:'#fff',borderRadius:'10px', marginTop: '60px'}}>Submit</button>
        </div>
    </form>
    <Tick isOpen={showModal} onClose={handleCloseModal}/>
    </>
  )
}

export default UserForm