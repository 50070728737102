import React from 'react'

const Popup = () => {
  return (
    <div >
        <div className='pop-center text-align'>
            <div className='popup-wrap'>
                <img src='/img/delete.png' alt='delete icon'></img>
                <p>ต้องการยกเลิก<br></br>การจัดส่งใช่หรือไม่</p>
                <div className='bg-flex-2'>
                    <button className='back-btn bg-back'>Back</button>
                    <button className='del-btn  bg-del'>Delete</button>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Popup