import React from 'react'
// import { VscAccount } from "react-icons/vsc";

const MerchantDetail = () => {
  return (
    <>
         <main className='main-merchant-edit'>
            <div className='merchant-name'>
               <img src='./img/merchant/account_circle.png' alt='profile icon'/>
                <input type="text" placeholder='Enter your name'/>
            </div>
            <div className='merchant-name'>
               <img src='./img/merchant/call.png' alt='call icon'/>
                <input type="text" placeholder='Enter mobile number'/>
            </div>
            <div className='merchant-name'>
               <img src='./img/merchant/mail.png' alt='mail icon'/>
                <input type="text" placeholder='Enter your email'/>
            </div>
            <div className='merchant-name'>
               <img src='./img/merchant/description.png' alt='description icon'/>
                <input type="text" placeholder='Description' minLength="4" maxLength="100"/>
            </div>


            <button className='voh-btn'>View Order History</button>
         </main>
    </>
  )
}

export default MerchantDetail