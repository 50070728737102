import React from 'react'

const ButtonMerchant = () => {
  return (
    <div className='a-r-btn'>
        <button className='acc-btn'>Accept</button>
        <button className='rej-btn'>Reject</button>
    </div>
  )
}

export default ButtonMerchant;