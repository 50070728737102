import React from 'react'

const BuildingBanner = () => {
  return (
    <>

    <header>
       
        <div  className='bo-header'>
            <div className='bo-banner'>
                <img src='./img/buildingowner/banner.png' alt='banner'/>
            </div>

            <section className='bo-wrap-sec'>
                <div className='bo-banner-text'>
                    <div className='bo-banner-logo'>
                        <img src='./img/buildingowner/banner-logo.png' alt="shop logo"/>
                        <img className='cam-icon' src='./img/merchant/cameraicon.png' alt="camera icon"/>
                    </div>
                    <div className='shop-banner'>
                        <h2>burj khalifa</h2>
                        <p>1 Mohammed Bin Rashid Boulevard Downtown Dubai Dubai, UAE</p>
                    </div>
                </div>
            </section>
            <div className='bo-relative'>
                <div className='bo-input-sett'>
                    <input type='search' placeholder='Search here' className='bo-search'/>
                    <img src='./img/buildingowner/settings.png' alt='setting icon'/>
                </div>
            </div>
        </div>
    </header>

    </>
  )
}

export default BuildingBanner